// -----------------------------------------------------------------------------
// Retailers
// -----------------------------------------------------------------------------

@use 'config/responsive';

.retailers__list {
    display: flex;
    justify-content: center;
    gap: 55px;
}

    .retailers__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 33%;
        height: clamp(90px, 13vw, 130px);

        @include responsive.mq($from: 'sm') {
            flex-basis: 30%;
        }
    }

        .retailers__logo--asda {
            width: 80%;
        }

        .retailers__logo--iceland {
            width: 82.5%;
        }

        .retailers__logo--sainsburys {
            width: 85%;
            transform: translateY(7.5%);
        }

        .retailers__logo--tesco {
            width: 80%;
        }

.retailers__note {
    max-width: 30em;
    font-size: clamp(24px, 3vw, 30px);
    line-height: 1.2;

    &:not(:first-child) {
        margin-top: 1.5625em;
    }
}
