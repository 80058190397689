// -----------------------------------------------------------------------------
// Wrapper
// -----------------------------------------------------------------------------

.wrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.wrapper--outer {
    max-width: 1600px;
}

.wrapper--copy {
    max-width: 1000px;
}
