// -----------------------------------------------------------------------------
// Type utilities
// -----------------------------------------------------------------------------

@use 'config/responsive';

.font-heading {
    font-weight: bold;
    text-transform: uppercase;
}



// Sizes
.size-current {
    font-size: 1em;
    line-height: inherit;
}

.size-body {
    font-size: 20px;
    line-height: 1.3;

    @include responsive.mq($from: 'sm') {
        font-size: 22px;
        line-height: 1.25;
    }

    @include responsive.mq($from: 'md') {
        font-size: 28px;
    }
}



// Alignment
.align-center {
    text-align: center;
}
