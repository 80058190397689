// -----------------------------------------------------------------------------
// Header
// -----------------------------------------------------------------------------

@use 'config/colors';

.header {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 18px;
    background-color: colors.$dark-green;
}

    .header__logo {
        width: clamp(130px, 20vw, 200px);
        filter: drop-shadow(0 0 15px rgba(#000, 0.333));
    }

//.header {
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    padding-top: clamp(30px, 4vw, 40px);
//    padding-bottom: clamp(30px, 4vw, 40px);
//}
//
//    .header__logo {
//        width: clamp(150px, 26vw, 260px);
//    }
