// -----------------------------------------------------------------------------
// Text block
// -----------------------------------------------------------------------------

.text-block {
    padding-top: clamp(200px, 30vw, 300px);
    padding-bottom: clamp(60px, 15vw, 150px);
    font-size: clamp(1.125rem, 2vw, 1.25rem);
}

    .text-block__heading {
        margin-bottom: clamp(38px, 5vw, 50px);
        font-size: clamp(42px, 8vw, 80px);
        line-height: 1;
    }
