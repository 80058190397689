// -----------------------------------------------------------------------------
// Link utilities
// -----------------------------------------------------------------------------

.fill-link::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
