// -----------------------------------------------------------------------------
// Images & media
// -----------------------------------------------------------------------------

img,
svg,
video,
audio,
canvas {
    vertical-align: middle;
    width: auto;
    max-width: 100%;
    height: auto;
}
