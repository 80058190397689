// -----------------------------------------------------------------------------
// Theme utilities
// -----------------------------------------------------------------------------

@use 'config/colors';
@use 'config/responsive';

// Background
.bg-white {
    background-color: #fff;
}

.bg-dark-green {
    background-color: colors.$dark-green;
}

.bg-light-green {
    background-color: colors.$light-green;
}

.bg-tan {
    background-color: colors.$tan;
}

.bg-cream {
    background-color: colors.$cream;
}

.bg-accent {
    background-color: var(--accent-color);
}



// Background image
.bg-fill {
    background-size: cover;
    background-position: center;
}

.bg-texture {
    background-image: url('../../public/images/texture.jpg');
    background-position: 50% 0;
    background-size: cover;
}

.bg-pattern {
    background-image: url('../../public/images/pattern.jpg');
    background-position: center;

    @include responsive.mq($until: 'lg') {
        background-size: 200px;
    }
}



// Foreground
.fg-white {
    color: #fff;
}

.fg-dark-green {
    color: colors.$dark-green;
}

.fg-light-green {
    color: colors.$light-green;
}

.fg-tan {
    color: colors.$tan;
}

.fg-cream {
    color: colors.$cream;
}

.fg-accent {
    color: var(--accent-color);
}



// Fill
.fill-current {
    fill: currentColor;
}

.fill-dark-green {
    fill: colors.$dark-green;
}

.fill-light-green {
    fill: colors.$light-green;
}

.fill-tan {
    fill: colors.$tan;
}

.fill-cream {
    fill: colors.$cream;
}

.fill-accent {
    fill: var(--accent-color);
}

.fill-white {
    fill: #fff;
}
