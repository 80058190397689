// -----------------------------------------------------------------------------
// Image overlay block
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'config/responsive';

.image-overlay__background {
    z-index: 1;
    height: clamp(400px, 66.666vw, 800px);
    background-attachment: fixed;

    @include responsive.mq($from: 'xl') {
        height: min(100vh, 1100px);
    }
}

.image-overlay__foreground {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 60px clamp(30px, 10vw, 100px);
    font-size: clamp(44px, 8vw, 80px);
    line-height: 1;

    @include responsive.mq($from: 'md') {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include responsive.mq($from: 'lg') {
        justify-content: center;
    }

    @include responsive.mq($from: 'xl') {
        font-size: 110px;
    }
}



// Background images
$images: (
    pork-pies: url('../../public/images/lifestyle/pork-pies.jpg'),
);

@each $name, $image in $images {
    .image-overlay__background--#{$name} {
        background-image: $image;
    }
}
