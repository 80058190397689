// -----------------------------------------------------------------------------
// Stack
// -----------------------------------------------------------------------------

.stack-y {
    display: flex;
    flex-direction: column;
}

// 1. Make sure <img> child elements with explicit width/height attributes
//    actually fill the container.
.stack-z {
    display: grid;

    > * {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        width: 100%; // [1]
    }
}
