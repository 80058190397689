// -----------------------------------------------------------------------------
// Social block
// -----------------------------------------------------------------------------

@use 'config/colors';
@use 'config/responsive';

.social {
    --mask-height: 250px;
    overflow: hidden;
    padding-top: clamp(70px, 15vw, 150px);
    padding-bottom: clamp(100px, 20vw, 200px);
    text-align: center;
    mask-image:
        linear-gradient(#fff, #fff),
        url('../../public/images/shape-bottom.svg');
    mask-repeat: no-repeat;
    mask-position:
        0 0,
        50% 100%;
    mask-size:
        100% calc(100% - (var(--mask-height) * 0.975)),
        100% var(--mask-height);

    @include responsive.mq($from: 'xl') {
        --mask-height: 350px;
    }
}

    .social__heading {
        font-size: clamp(50px, 10vw, 120px);
        line-height: 1;
    }

    .social__feed {
        margin-block: clamp(50px, 10vw, 130px);
    }



// Social feed
.social-feed {
    display: flex;
    justify-content: center;
}

    .social-feed__image {
        flex-shrink: 0;
        width: clamp(100px, 25vw, 325px);

        &:nth-child(odd) {
            transform: rotate(-6.75deg);
        }

        &:nth-child(even) {
            transform: rotate(2deg);
        }
    }



// Social menu
.social-menu {
    display: flex;
    justify-content: center;
    font-size: clamp(34px, 5vw, 52px);
}

    .social-menu__item {
        & + & {
            margin-left: 0.333em;

            @include responsive.mq($from: 'sm') {
                margin-left: 0.5em;
            }

            @include responsive.mq($from: 'lg') {
                margin-left: 0.85em;
            }
        }
    }

        .social-menu__link {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.7em;
            height: 1.7em;
            border-radius: 50%;
            color: #fff;
        }

            .social-menu__icon {
                fill: currentColor;
            }
