// -----------------------------------------------------------------------------
// Range
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'config/colors';
@use 'config/responsive';

.range {
    overflow: hidden;
    text-align: center;

    &:nth-child(odd) {
        @include responsive.mq($from: 'lg') {
            --caption-position: 1 / 2;
            --caption-align: flex-end;
            --caption-spacing-left: var(--page-padding);
        }
    }

    &:nth-child(even) {
        @include responsive.mq($from: 'lg') {
            --caption-position: 2 / 3;
            --caption-align: flex-start;
            --caption-spacing-right: var(--page-padding);
        }
    }
}

    .range__wrapper {
        display: flex;
        flex-direction: column;
        //padding-top: clamp(100px, 16.666vw, 200px);
        padding-bottom: clamp(100px, 16.666vw, 200px);
    }

        .range__header {
            @include responsive.mq($from: 'lg') {
                margin-bottom: 72px;
            }
        }

        @include responsive.mq($until: 'lg') {
            .range__header--pork-pies {
                margin-bottom: percentage(math.div(50, 400));
            }

            .range__header--pickle-pies {
                margin-bottom: percentage(math.div(60, 400));
            }

            .range__header--snacking {
                margin-bottom: percentage(math.div(130, 400));
            }
        }

            .range__heading {
                z-index: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

                .range__lockup {
                    max-width: 1360px;
                    overflow: visible;
                }

            .range__overlay {
                z-index: 2;
                display: flex;
                align-items: flex-start;
                //background-color: rgba(#f00, 0.2);
            }

                .range__image--pork-pie {
                    width: percentage(math.div(630, 1600));
                    margin-left: auto;
                    margin-top: auto;
                    transform: translate(30%, 25%);
                }

                .range__image--pickle-pie {
                    width: percentage(math.div(700, 1600));
                    transform: translate(-28%, 60%);
                }

                .range__image--cocktail-sausages {
                    width: percentage(math.div(980, 1600));
                    margin-left: auto;
                    margin-top: auto;
                    transform: translate(45%, 75%);
                }

        .range__grid {
            @include responsive.mq($from: 'lg') {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }

            .range__caption {
                grid-column: var(--caption-position);
                display: flex;
                justify-content: var(--caption-align);
                //padding-top: clamp(35px, 5vw, 50px);

                @include responsive.mq($until: 'lg') {
                    padding-left: var(--page-padding);
                    padding-right: var(--page-padding);
                }

                @include responsive.mq($from: 'lg') {
                    margin-left: var(--caption-spacing-left);
                    margin-right: var(--caption-spacing-right);
                }
            }

                .range__description {
                    @include responsive.mq($until: 'lg') {
                        max-width: 18em;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    @include responsive.mq($from: 'lg') {
                        max-width: 23em;
                    }
                }

                    .range__button {
                        margin-top: clamp(32px, 5.2vw, 52px);
                    }
