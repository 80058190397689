// -----------------------------------------------------------------------------
// Contact block
// -----------------------------------------------------------------------------

@use 'config/responsive';

.contact {
    padding-top: clamp(80px, 11.5vw, 115px);
    padding-bottom: clamp(80px, 11.5vw, 115px);
    text-align: center;
}

    .contact__heading {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.4em;
        font-size: clamp(50px, 9.6vw, 96px);
        line-height: 1;

        > span:first-child {
            font-size: 0.75em;
        }
    }

    .contact__intro {
        max-width: 22em;
        margin-bottom: clamp(38px, 4.5vw, 45px);
    }
