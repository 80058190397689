// -----------------------------------------------------------------------------
// Figure
// -----------------------------------------------------------------------------

@use 'config/responsive';

.figure {
    --figure-mask-size: 1100px;

    @include responsive.mq($from: 'md') {
        --caption-width: 45fr;
        --image-width: 55fr;
        display: grid;
        height: clamp(600px, 66.666vw, 800px);

        &:nth-child(odd) {
            --figure-mask-position: 0 50%;
            --figure-mask-edge: left;
            grid-template-areas: 'caption image';
            grid-template-columns: var(--caption-width) var(--image-width);
        }

        &:nth-child(even) {
            --figure-mask-position: 100% 50%;
            --figure-mask-edge: right;
            grid-template-areas: 'image caption';
            grid-template-columns: var(--image-width) var(--caption-width);
        }
    }

    @include responsive.mq($from: 'lg') {
        --caption-width: 42fr;
        --image-width: 58fr;
    }

    @include responsive.mq($from: 'xl') {
        height: min(100vh, 1100px);
    }
}

    .figure__clear {
        @include responsive.mq($until: 'md') {
            display: none;
        }
    }

    .figure__image {
        grid-area: image;
        overflow: hidden;
        background-color: rgba(#000, 0.1);

        @include responsive.mq($until: 'md') {
            height: 600px;
        }

        @include responsive.mq($from: 'md') {
            mask-image:
                url('../../public/images/shape.svg'),
                linear-gradient(#fff, #fff);
            mask-repeat: no-repeat;
            mask-position:
                var(--figure-mask-position),
                var(--figure-mask-edge) calc(var(--figure-mask-size) / 2) top 0;
            mask-size:
                var(--figure-mask-size),
                100% 100%;
        }
    }

        .figure__background {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

    .figure__caption {
        grid-area: caption;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: var(--page-padding);
        padding-right: var(--page-padding);

        @include responsive.mq($until: 'md') {
            padding-top: 10%;
            padding-bottom: 10%;
        }
    }

        .figure__lockup--small {
            width: clamp(400px, 66.666vw, 510px);

            @include responsive.mq($from: 'md') {
                display: none;
            }
        }

        .figure__lockup--large {
            @include responsive.mq($until: 'md') {
                display: none;
            }
        }
