// -----------------------------------------------------------------------------
// Intro
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'config/responsive';

$base-width: 975px;

.intro {
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
}

    .intro__wrapper {
        max-width: clamp(500px, 70vw, $base-width);

        @include responsive.mq($from: 'xl') {
            max-width: $base-width;
        }
    }

        .intro__background {
            z-index: 1;
            padding-top: 100%;
            mask-image: url('../../public/images/shape.svg');
            mask-size: 100% 100%;
        }

        .intro__foreground {
            z-index: 2;
        }

            .intro__lockup {
                margin-bottom: percentage(math.div(62px, $base-width));
            }

            .intro__pack {
                align-self: center;
                width: percentage(math.div(643px, $base-width));
            }

            .intro__footer {
                margin-top: 0.5em;

                @include responsive.mq($until: 'lg') {
                    max-width: 16em;
                }
            }
