// -----------------------------------------------------------------------------
// Menu
// -----------------------------------------------------------------------------

@use 'config/responsive';

.menu {
    --menu-spacing: 1em;
    display: flex;
    justify-content: center;

    @include responsive.mq($from: 'sm') {
        --menu-spacing: 2em;
    }
}

    .menu__item {
        display: flex;

        &:not(:last-child) {
            margin-right: var(--menu-spacing);

            &::after {
                content: '|';
                margin-left: var(--menu-spacing);
            }
        }
    }

        .menu__link {
            text-decoration: none;
        }
