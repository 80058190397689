// -----------------------------------------------------------------------------
// Fonts
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Fibra One';
    font-weight: normal;
    font-display: swap;
    src:
        url('../../fonts/fibra-one-semibold.woff2') format('woff2'),
        url('../../fonts/fibra-one-semibold.woff') format('woff');
}

@font-face {
    font-family: 'Fibra One';
    font-weight: bold;
    font-display: swap;
    src:
        url('../../fonts/fibra-one-heavy.woff2') format('woff2'),
        url('../../fonts/fibra-one-heavy.woff') format('woff');
}
