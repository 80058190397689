// -----------------------------------------------------------------------------
// Video
// -----------------------------------------------------------------------------

.video-block {
    //position: sticky;
    //top: var(--header-height);
}

    .video-block__video {
        width: 100%;
    }
