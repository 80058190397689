// -----------------------------------------------------------------------------
// Type container
// -----------------------------------------------------------------------------

.type {
    p,
    ul,
    ol {
        margin-bottom: 1em;
    }

    h2,
    h3 {
        margin-top: 1.2em;
        margin-bottom: 0.5em;
        font-weight: bold;
        line-height: 1.125;
        text-transform: uppercase;
    }

    h2 {
        font-size: clamp(32px, 4.2vw, 42px);
    }

    h3 {
        font-size: clamp(24px, 3vw, 30px);
    }

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}
