// -----------------------------------------------------------------------------
// Animation
// -----------------------------------------------------------------------------

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.shape-rotate {
    animation-name: rotate;
    animation-duration: 90s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
