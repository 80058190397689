// -----------------------------------------------------------------------------
// Type
// -----------------------------------------------------------------------------

html {
    font-family: 'Fibra One', sans-serif;
    font-size: 100%;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



// Sensible defaults
a {
    color: currentColor;
}

b,
strong {
    font-weight: bold;
}

sup {
    font-size: 0.75em;
    vertical-align: super;
}

pre {
    font-family: monospace;
}

::selection {
}
