// -----------------------------------------------------------------------------
// History
// -----------------------------------------------------------------------------

@use 'config/responsive';

.history {
    overflow: hidden;
}

    .history__background {
        z-index: 1;
        mask-image: url('../../public/images/shape.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 93.75%;
    }

    .history__foreground {
        z-index: 2;
        padding-top: 140px;
        padding-bottom: 140px;

        @include responsive.mq($from: 'md') {
            padding-top: clamp(200px, 27vw, 400px);
            padding-bottom: clamp(200px, 27vw, 400px);
        }
    }

        .history__heading {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            font-size: clamp(36px, 6.666vw, 50px);
            line-height: 1;

            @include responsive.mq($from: 'md') {
                font-size: clamp(70px, 6.25vw, 90px);
                line-height: 0.95;
            }

            > span:last-child {
                font-size: 1.333em;
            }
        }

        .history__description {
            max-width: 36em;
        }
