// -----------------------------------------------------------------------------
// Window
// -----------------------------------------------------------------------------

@use 'config/colors';
@use 'config/responsive';

.window {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: clamp(80px, 10vw, 100px);
    padding-bottom: clamp(80px, 19vw, 190px);
}

    .window__close {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        padding: 0;
        font-family: inherit;
        color: #fff;
        background-color: transparent;
        cursor: pointer;

        @include responsive.mq($until: 'sm') {
            top: 10px;
            right: 10px;
            width: 50px;
        }
    }

    .window__wrapper {
        max-width: 1080px;
    }

        .window__heading {
            margin-bottom: 0.666em;
            font-size: clamp(44px, 7.2vw, 72px);
            line-height: 1;

            @include responsive.mq($from: 'md') {
                margin-bottom: 1em;
            }
        }
